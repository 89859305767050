import React, { useState, useEffect } from "react";
import "./style/Style.scss";

function TypeOutText(props) {
  const [currentText, setCurrentText] = useState("");
  const [typingFinished, setTypingFinished] = useState(false);

  useEffect(() => {
    if (currentText.length < props.text.length && !typingFinished) {
      setTimeout(() => {
        setCurrentText(currentText + props.text[currentText.length]);
      }, parseFloat(props.timeout));
    } else {
      if (!typingFinished) {
        setTimeout(() => {
          setTypingFinished(true);
        }, parseFloat(props.pauseAtFinish));
      } else {
        if (currentText.length > 0) {
          setTimeout(() => {
            setCurrentText(currentText.slice(0, currentText.length - 1));
          }, parseFloat(props.timeout));
        }
      }
    }
  }, [currentText, typingFinished]);

  return <span>{currentText}</span>;
}

function App() {
  const headerFrames = [
    <div className="claim-wrapper headline-text">
      We do <TypeOutText text="coding" timeout="100" pauseAtFinish="2000" />
    </div>,
    <div className="claim-wrapper headline-text">
      We do{" "}
      <span className="animation-block">{splitTextToChars("design", 30)}</span>
    </div>,
    <div className="claim-wrapper headline-text">
      We do{" "}
      <span className="animation-zipping">
        {splitTextToChars("marketing", 30)}
      </span>
    </div>,
    <div className="claim-wrapper headline-claim animation-fade">
      We fix problems you don't even know you have in the ways you won't
      understand.
    </div>,
  ];

  const [currentHeaderFrame, setCurrentHeaderFrame] = useState(0);

  function splitTextToChars(text, delayAnimation) {
    return (
      <span className="split-text">
        {text.split("").map((char, index) => {
          const style = { animationDelay: 0.5 + index / delayAnimation + "s" };
          return (
            <span className="char" key={index} style={style}>
              {char}
            </span>
          );
        })}
      </span>
    );
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      if (currentHeaderFrame + 1 < headerFrames.length) {
        setCurrentHeaderFrame(currentHeaderFrame + 1);
      }
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [currentHeaderFrame]);

  return (
    <div className="wrapper">
      <div className="content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlnsserif="http://www.serif.com/"
          width="100%"
          height="100%"
          viewBox="0 0 380 150"
          version="1.1"
          xmlSpace="preserve"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5,
          }}
        >
          <path
            className="logo-animation"
            d="M9.704,137.402L67.843,14.554L117.485,118.704C117.485,118.704 135.735,119.191 140.115,117.487C144.495,115.784 169.803,111.404 167.856,71.009C165.909,30.615 129.652,33.291 125.515,33.291L87.797,33.291L78.307,13.824L128.922,13.824C128.922,13.824 158.026,13.094 171.263,28.181C184.5,43.268 194.137,56.409 188.053,93.883C181.97,131.358 149.362,136.468 146.929,136.954C144.495,137.441 133.545,137.684 133.545,137.684L76.36,137.684L83.417,121.381L97.774,121.381L68.087,52.759L37.912,121.624L73.197,121.624L66.14,137.198L9.704,137.402Z"
            style={{ fill: "none", stroke: "black", strokeWidth: "1.4px" }}
          />
          <g transform="matrix(1.08397,0,0,1.1374,-30.4606,-10.4414)">
            <circle
              className="logo-animation"
              cx="331.968"
              cy="71.563"
              r="19.923"
              style={{ fill: "none" }}
            />
          </g>
          <g transform="matrix(1.9313,0,0,1.87786,-311.898,-63.8868)">
            <circle
              className="logo-animation"
              cx="331.968"
              cy="71.563"
              r="19.923"
              style={{ fill: "none", stroke: "black", strokeWidth: "0.73px" }}
            />
          </g>
          <g transform="matrix(1.9313,0,0,1.87786,-393.265,-63.8868)">
            <path
              className="logo-animation"
              d="M331.968,51.639C352.439,51.639 352.698,69.593 352.698,69.593C352.698,69.593 352.926,89.614 352.213,96.441C350.595,111.932 336.072,112.91 333.48,113.014C328.391,113.219 320.022,113.069 314.683,104.417C311.117,98.639 312.099,88.457 312.099,88.457L320.785,94.365C320.785,94.365 321.74,105.361 331.968,105.916C335.197,106.091 340.323,103.508 341.882,99.71C343.873,94.861 343.15,88.05 343.15,88.05C343.15,88.05 336.111,91.486 331.968,91.486C320.972,91.486 312.044,82.559 312.044,71.563C312.044,60.567 320.972,51.639 331.968,51.639Z"
              style={{ fill: "none", stroke: "black", strokeWidth: "0.73px" }}
            />
          </g>
          <g transform="matrix(1.08397,0,0,1.1374,-30.4606,-10.4414)">
            <circle
              className="logo-animation"
              cx="331.968"
              cy="71.563"
              r="19.923"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.25px" }}
            />
          </g>
          <g transform="matrix(1.08397,0,0,1.1374,-111.98,-10.4414)">
            <circle
              className="logo-animation"
              cx="331.968"
              cy="71.563"
              r="19.923"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.26px" }}
            />
          </g>
        </svg>
        <div className="animation-wrapper">
          {headerFrames[currentHeaderFrame]}
        </div>

        <div className="contact-us-wrapper">
          <div className="contact-us-phone">
            <img src={require("./images/phone.png")} alt="phone_icon" />{" "}
            <div>+420 602 266 494</div>
          </div>

          <a href="mailto:info@ad-go.cz">
            <div className="button">
              <div className="slide"></div>
              <span>Contact Us!</span>
            </div>
          </a>

          <div className="claim">
            <div>We are growing.</div>
            <div>
              See our work at{" "}
              <a className="underline" href="https://raevtech.com">
                raevtech.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
